@import "./animations";

.input-row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.card-input {
  position: relative;
  width: 100%;
  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    padding: 10px;
  }
  .input-icon {
    position: relative;
  }
  button,
  & > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    color: var(--main-color);
    cursor: pointer;
    &.preffix {
      left: 0px;
    }
  }

  & > i {
    width: 42px;
  }

  .mat-form-field {
    .mat-form-field-infix {
      width: 100%;
      padding: 0 !important;
      border-top: 0 solid transparent !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    input {
      height: auto !important;
      box-sizing: border-box;
    }
  }

  &.not_border {
    input,
    textarea,
    select,
    .mat-mdc-select-trigger {
      background-color: var(--input-color);
      border: 1px solid var(--input-color);
    }
  }

  input,
  textarea,
  select,
  .mat-mdc-select-trigger {
    width: 100%;
    align-items: center;
    border: 1px solid transparent;
    padding: 8px 14px;
    border-radius: var(--br);
    // background-color: var(--input-color);
    gap: 8px;

    border: 1px solid var(--green-color);
    background: white;

    &:read-only {
      opacity: 0.5;
    }

    &[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &.ng-touched.ng-invalid {
      border-color: var(--red-color);
      background-color: #ffd7d7;
      @include shake;
    }
    &[type="file"] {
      display: none;
    }

    &:focus-visible {
      outline: none;
      // outline: 1px solid var(--text-color);
    }
  }
  &:has(.suffix) {
    input {
      padding-right: 48px;
    }
  }
  &:has(.preffix) {
    input {
      padding-left: 42px;
    }
  }

  &.search_name {
    position: relative;
    width: 100%;

    input {
      width: 100%;
    }
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--main-color);
      cursor: pointer;
    }

    &.right {
      input {
        padding-right: 2rem;
      }
      i {
        right: 0.7rem;
      }
    }

    &.left {
      input {
        padding-left: 2rem;
      }
      i {
        left: 0.7rem;
      }
    }
  }

  .input_partner {
    display: flex;
    max-width: 352px;
    height: 41px;
    padding: 12px 40px 12px 12px;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
    background: var(--primary-bg);
    gap: 8px;

    &[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &.ng-touched.ng-invalid {
      border-color: var(--red-color);
      @include shake;
    }
    &[type="file"] {
      display: none;
    }
  }

  .mat-select {
    padding: 10px 16px;
  }

  .upload-style {
    border-radius: var(--br);
    border: 1px dashed var(--main-color);
    cursor: pointer;
    padding: 0;
    &.ng-touched.ng-invalid {
      border-color: var(--red-color);
      border-style: solid;
      @include shake;
    }
  }

  .mat-button {
    height: 41px;
  }

  & + .card-input {
    margin-top: 1rem;
  }

  &.border-green {
    input,
    textarea,
    select,
    .mat-select {
      border: 1px solid var(--green-color);
      background: white;
    }
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

.mat-form-field.mat-focused .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
  background-color: transparent !important;
}

.mat-form-field-infix {
  display: flex;
}

html {
  .mdc-radio__background {
    display: block;
  }
}

.mdc-checkbox {
  .mdc-checkbox__native-control:enabled {
    & ~ .mdc-checkbox__background {
      .mdc-checkbox__mixedmark,
      .mdc-checkbox__checkmark {
        --mdc-checkbox-selected-checkmark-color: #fff;
      }
    }
  }
}
