.mat-mdc-dialog-panel {
  --mdc-dialog-container-shape: 1rem;
  --mat-dialog-with-actions-content-padding: 2rem 1rem 1rem 1rem;
  --mat-dialog-actions-padding: 1rem 1rem 2rem 1rem;
  width: 100%;

  // .mdc-dialog__container {
  //   padding: 1rem;
  // }

  &.small {
    --mat-dialog-container-max-width: 320px;
  }

  &.medium {
    --mat-dialog-container-max-width: 480px;
  }
}
