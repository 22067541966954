@use "@angular/material" as mat;
@use "styles/theming" as theming;

@include mat.core();

$my-primary: mat.define-palette(theming.$my-palette, 500);
$my-accent: mat.define-palette(theming.$my-palette, 200, 100, 400);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

@import "ngx-toastr/toastr";
@import "styles/animations";
@import "styles/button";
@import "styles/dialog";
@import "styles/input";
@import "styles/media";
@import "styles/page";
@import "styles/scroll";
@import "styles/skeleton";
@import "styles/transitions";
@import "styles/typography";
@import "styles/variables";
// @import 'node_modules/angular-notifier/styles';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  @include media("<=400px") {
    line-break: anywhere;
    // flex-wrap: wrap;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.icon {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 10000000 !important;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--green-color);
}

.medium-zoom-image--opened {
  z-index: 9999 !important;
  object-fit: cover !important;
}

.medium-zoom--opened .medium-zoom-overlay {
  z-index: 100 !important;
  background: rgba(0, 0, 0, 0.32) !important;
}

::ng-deep {
  .mdc-tab {
    min-width: 160px;
  }
}

.toast-container {
  font-size: 0.8rem;
}
