::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--light-green-color);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: var(--secondary-bg);
  border-radius: 6px;
}
