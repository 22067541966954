@import "./media";

main.page {
  width: var(--width);
  max-width: var(--max-width);
  margin: 0 auto;

  @include media("<=400px") {
    width: 95%;
  }

  min-height: calc(100vh - 70px);
  background-color: var(--primary-bg);
  border: 1px solid var(--line-color);
  padding: 0 0 56px 0;

  .container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0;
  }
}

// main.page {
//   min-height: calc(100vh - 70px);
//   background-color: var(--primary-bg);
//   border: 1px solid var(--line-color);
//   padding: 56px 4%;
//   .container {
//     max-width: var(--max-width);
//     margin: 0 auto;
//     padding: 0 1rem;
//   }
// }
