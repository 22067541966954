:root {
  font-size: calc(16px + 0.3125vw);
  --ts: all 0.3s ease-in-out;
  --br: 0.4rem;
  --time-c-b: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --elastic-in-out: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  --text-color: #000000;
  --main-color: #191a19;
  --primary-bg: #fff;
  --secondary-bg: #e9e9e9;

  --yellow-color: #f2af4c;
  --green-color: #006633;
  --light-green-color: #68b15f;
  --red-color: #b10707;
  --gray-color: #7e807e;
  --input-color: #f3f5f0;
  --gray-asks: rgba(12, 13, 12, 0.03);
  --gray-answers: #4b4d4b;
  --card-others: #eff2ef;

  --nav-color: #fafcf7;

  --skeleton-bg: #e2e2e2;
  --skeleton-bg-dark: #d4d4d4;
  --skeleton-bg-themed: #474343;
  --skeleton-bg-dark-themed: #2b2727;

  --avatar-dark-color: #000000;
  --avatar-light-color: #ffffff;
  --avatar-br: 50%;
  --avatar-ratio: 1/1;

  // ? Navbar variables
  --width: 80%;
  --max-width: 1920px;

  --navbar-logo-height: 50px;
  --navbar-top-bg: #302b2b; // transparent
  --navbar-top-color: #fff;
}
