@import "./media";

a:-webkit-any-link:not(
    .mat-mdc-icon-button,
    .mat-mdc-button,
    .mat-mdc-button-base
  ) {
  color: var(--main-color);
}

html {
  .mat-mdc-unelevated-button,
  .mat-mdc-button {
    --mdc-text-button-container-height: 40px;
    --mdc-filled-button-container-height: 40px;
    --mdc-filled-button-container-shape: 12px;

    &.extended {
      --mdc-filled-button-container-height: 48px !important;
    }

    loading {
      display: inline-flex;
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    loading,
    .text {
      transition: var(--ts);
    }
    &.loading {
      .text {
        opacity: 0;
      }
      loading {
        opacity: 1;
      }
    }

    .mdc-button__label {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      line-break: anywhere;
    }

    .mat-mdc-button-touch-target {
      height: 40px;
    }

    &.mat-secundary {
      background-color: var(--primary-bg);
      color: var(--green-color);
    }

    &.mat-accent {
      background-color: var(--light-green-color);
      color: var(--primary-bg);
    }
  }
  .mat-mdc-button-base {
    text-decoration: none;

    i[icon] {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center;
    }
    &.wrap {
      flex-wrap: wrap;
    }
    &.full {
      button {
        width: 100%;
      }
    }
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 0.9rem;
  }

  .mat-datepicker-toggle {
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
